export const onRouteUpdate = async ({ location }) => {
    const { language, userAgent } = window.navigator
    const { pathname } = location
    const pagePath = pathname || "/"

    const properties = {
        pagePath,
        language,
        userAgent
    }

    if (!window.analytics || typeof window.analytics.page !== "function") {
        console.warn("Segment analytics.js is not available.")
    } else {
        window.analytics.page(pagePath, properties)
    }

    if (typeof window.gtag !== "function") {
        console.warn("Google Analytics gtag() is not available.")
    } else {
        window.gtag("config", "G-53FG4082ZC", {
            page_path: pagePath
        })
    }
}