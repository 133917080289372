exports.components = {
  "component---src-pages-aniversarioley-2023-tsx": () => import("./../../../src/pages/aniversarioley2023.tsx" /* webpackChunkName: "component---src-pages-aniversarioley-2023-tsx" */),
  "component---src-pages-avisodeprivacidad-tsx": () => import("./../../../src/pages/avisodeprivacidad.tsx" /* webpackChunkName: "component---src-pages-avisodeprivacidad-tsx" */),
  "component---src-pages-chimex-tsx": () => import("./../../../src/pages/chimex.tsx" /* webpackChunkName: "component---src-pages-chimex-tsx" */),
  "component---src-pages-frutas-tsx": () => import("./../../../src/pages/frutas.tsx" /* webpackChunkName: "component---src-pages-frutas-tsx" */),
  "component---src-pages-gamesa-tsx": () => import("./../../../src/pages/gamesa.tsx" /* webpackChunkName: "component---src-pages-gamesa-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-terminos-tsx": () => import("./../../../src/pages/terminos.tsx" /* webpackChunkName: "component---src-pages-terminos-tsx" */)
}

